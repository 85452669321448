/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Buefy from 'buefy'
import BackToTop from 'vue-backtotop'
import cookieconsent from 'vue-cookieconsent-component'
import carousel from 'vue-owl-carousel'

window.Vue = require('vue');

Vue.use(Buefy);

Vue.use(BackToTop);
Vue.component('carousel', carousel);
Vue.component('cookie-consent', cookieconsent);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data:{
        isActive: false
    },
    methods: {
        showModal() {
            this.isActive = true;
        },
        closeModal() {
            this.isActive = false;
        },
        showModalPortfolio(target) {
            this.$refs[target].classList.add('is-active');
            const imageLazy = this.$refs[target].querySelector('.lazy');
            if(imageLazy !== null){
                imageLazy.src = imageLazy.getAttribute('data-src');
            }
        },
        closeModalPortfolio(target) {
            this.$refs[target].classList.remove('is-active');
        },
        showModalGeneric(target) {
            this.$refs[target].classList.add('is-active');
        },
        closeModalGeneric(target) {
            this.$refs[target].classList.remove('is-active');
        },
    },
    mounted() {
        const session_modal = document.getElementsByClassName("session_modal");
        if(session_modal.length > 0){
            this.showModal();
        }
    }
});
