<template>
    <div class="block_website_price_simulator">
        <section class="hero is-primary has-text-centered" id="sticky" :class="{ blockFixed: isFixed }">
            <div class="hero-body">
                <div class="container">
                    <h5 class="title">
                        {{ total_price }},00 €
                    </h5>
                </div>
            </div>
        </section>
        <div class="section" :class="{ siteScrollLockFixed: isFixed }">
            <div class="container">
                <div class="columns has-text-centered" v-if="!sentForm">
                    <div class="column">
                        <div v-if="step === 1">
                            <h2 class="title">Tipologia</h2>
                            <div class="field" v-if="!website_clone">
                                <input id="website_new" type="checkbox" @change="updatePrice" name="website_new" class="switch is-rounded" v-model="website_new" checked="checked">
                                <label for="website_new">Sito nuovo</label>
                            </div>
                            <div class="field" v-if="!website_new">
                                <input id="website_clone" type="checkbox" @change="updatePrice" name="website_clone" class="switch is-rounded" v-model="website_clone" checked="checked">
                                <label for="website_clone">Clone di un progetto esistente</label>
                            </div>
                            <hr>
                            <div v-if="website_new">
                                <div class="field">
                                    <input id="technology_php_showcase" type="checkbox" @change="updatePrice" name="technology_php_showcase" class="switch is-rounded" v-model="technology_php_showcase" checked="checked">
                                    <label for="technology_php_showcase">Sito vetrina (massimo 3 pagine)</label>
                                </div>
                                <div class="field">
                                    <input id="technology_php_webapp" type="checkbox" @change="updatePrice" name="technology_php_webapp" class="switch is-rounded" v-model="technology_php_webapp" checked="checked">
                                    <label for="technology_php_webapp">Web app (massimo 3 pagine)</label>
                                </div>
                                <div class="field">
                                    <input id="technology_wordpress" type="checkbox" @change="updatePrice" name="technology_wordpress" class="switch is-rounded" v-model="technology_wordpress" checked="checked">
                                    <label for="technology_wordpress">Sito WordPress da zero</label>
                                </div>
                                <div class="field">
                                    <input id="technology_wordpress_theme" type="checkbox" @change="updatePrice" name="technology_wordpress_theme" class="switch is-rounded" v-model="technology_wordpress_theme" checked="checked">
                                    <label for="technology_wordpress_theme"><span data-tooltip="Da fornire il tema scelto">Sito WordPress con tema fornito</span></label>
                                </div>
                                <div class="field">
                                    <input id="technology_e_commerce" type="checkbox" @change="updatePrice" name="technology_e_commerce" class="switch is-rounded" v-model="technology_e_commerce" checked="checked">
                                    <label for="technology_e_commerce">e-commerce WordPress</label>
                                </div>
                                <div class="field">
                                    <input id="technology_laravel_e_commerce" type="checkbox" @change="updatePrice" name="technology_laravel_e_commerce" class="switch is-rounded" v-model="technology_laravel_e_commerce" checked="checked">
                                    <label for="technology_laravel_e_commerce">e-commerce Laravel</label>
                                </div>
                                <hr>
                            </div>
                            <div class="">
                                <h3 class="title">Il sito deve essere responsive?</h3>
                                <div class="field">
                                    <input id="website_responsiveness" type="checkbox" @change="updatePrice" name="website_responsiveness" class="switch is-rounded" v-model="website_responsiveness" checked="checked">
                                    <label for="website_responsiveness"><span class="has-tooltip-multiline" data-tooltip="Vuoi che il sito sia fruibile sia da desktop che da mobile e tablet?">Responsive</span></label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control has-text-centered button-margin-20">
                                    <button class="button is-primary" @click="secondStep">Avanti</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="step === 2">
                            <h2 class="title">Feature</h2>
                            <div v-if="website_new">
                                <div class="columns">
                                    <div class="column">
                                        <h3 class="subtitle">Add-on</h3>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter has-text-left">
                                        <div class="block_checkbox">
                                            <input id="check_domain" type="checkbox" @change="updatePrice" name="check_domain" class="switch is-rounded" v-model="check_domain" checked="checked">
                                            <label for="check_domain"><span class="has-tooltip-multiline" data-tooltip="Il dominio deve essere fornito, questa opzione nel caso bisogna creare l'host su una VPS">Dominio</span></label>
                                        </div>
                                        <div class="block_checkbox" v-if="technology_laravel_e_commerce || technology_php_webapp || technology_php_showcase">
                                            <input id="check_blog_laravel" type="checkbox" @change="updatePrice" name="check_blog_laravel" class="switch is-rounded" v-model="check_blog_laravel" checked="checked">
                                            <label for="check_blog_laravel">Blog</label>
                                        </div>
                                        <div class="block_checkbox" v-if="technology_wordpress || technology_wordpress_theme || technology_e_commerce">
                                            <input id="check_blog" type="checkbox" @change="updatePrice" name="check_blog" class="switch is-rounded" v-model="check_blog" checked="checked">
                                            <label for="check_blog">Blog</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_cookie" type="checkbox" @change="updatePrice" name="check_cookie" class="switch is-rounded" v-model="check_cookie" checked="checked">
                                            <label for="check_cookie">Popup per la legge dei cookie</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_multi_language" type="checkbox" @change="updatePrice" name="check_multi_language" class="switch is-rounded" v-model="check_multi_language" checked="checked">
                                            <label for="check_multi_language">Sito multi lingua</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_guide" type="checkbox" @change="updatePrice" name="check_guide" class="switch is-rounded" v-model="check_guide" checked="checked">
                                            <label for="check_guide">Manuale di utilizzo del portale</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_content_insert" type="checkbox" @change="updatePrice" name="check_content_insert" class="switch is-rounded" v-model="check_content_insert" checked="checked">
                                            <label for="check_content_insert">Inserimento dei contenuti da parte dello staff</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column">
                                        <h3 class="subtitle">Statistiche</h3>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter has-text-left">
                                        <div class="block_checkbox">
                                            <input id="check_search_console" type="checkbox" @change="updatePrice" name="check_search_console" class="switch is-rounded" v-model="check_search_console" checked="checked">
                                            <label for="check_search_console">Search console</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_analytics" type="checkbox" @change="updatePrice" name="check_analytics" class="switch is-rounded" v-model="check_analytics" checked="checked">
                                            <label for="check_analytics">Google analytics</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column">
                                        <h3 class="subtitle">SEO</h3>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter has-text-left">
                                        <div class="block_checkbox">
                                            <input id="check_seo" type="checkbox" @change="updatePrice" name="check_seo" class="switch is-rounded" v-model="check_seo" checked="checked">
                                            <label for="check_seo">Ottimizazzione SEO onsite</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_social" type="checkbox" @change="updatePrice" name="check_social" class="switch is-rounded" v-model="check_social" checked="checked">
                                            <label for="check_social">Share social</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_optimize_speed" type="checkbox" @change="updatePrice" name="check_optimize_speed" class="switch is-rounded" v-model="check_optimize_speed" checked="checked">
                                            <label for="check_optimize_speed">Ottimizazzione velocità</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_index" type="checkbox" @change="updatePrice" name="check_index" class="switch is-rounded" v-model="check_index" checked="checked">
                                            <label for="check_index"><span class="has-tooltip-multiline" data-tooltip="Permette al sito di essere indicizzato dai motori di ricerca">Indicizzazione</span></label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_ssl" type="checkbox" @change="updatePrice" name="check_ssl" class="switch is-rounded" v-model="check_ssl" checked="checked">
                                            <label for="check_ssl">Sito in https</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column">
                                        <h3 class="subtitle">Gestione tecnica</h3>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter has-text-left">
                                        <div class="block_checkbox">
                                            <input id="check_backup_db" type="checkbox" @change="updatePrice" name="check_backup_db" class="switch is-rounded" v-model="check_backup_db" checked="checked">
                                            <label for="check_backup_db">Backup DB</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_source_code" type="checkbox" @change="updatePrice" name="check_source_code" class="switch is-rounded" v-model="check_source_code" checked="checked">
                                            <label for="check_source_code">Accesso al codice sorgente</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <h3 class="subtitle">Supporto per browser aggiuntivi</h3>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter has-text-left">
                                        <div class="block_checkbox">
                                            <input id="check_ie_11" type="checkbox" @change="updatePrice" name="check_ie_11" class="switch is-rounded" v-model="check_ie_11" checked="checked">
                                            <label for="check_ie_11">IE 11</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_ie_10" type="checkbox" @change="updatePrice" name="check_ie_10" class="switch is-rounded" v-model="check_ie_10" checked="checked">
                                            <label for="check_ie_10">IE 10</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_ie_9" type="checkbox" @change="updatePrice" name="check_ie_9" class="switch is-rounded" v-model="check_ie_9" checked="checked">
                                            <label for="check_ie_9">IE 9</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_ie_8" type="checkbox" @change="updatePrice" name="check_ie_8" class="switch is-rounded" v-model="check_ie_8" checked="checked">
                                            <label for="check_ie_8">IE 8</label>
                                        </div>
                                        <div class="block_checkbox">
                                            <input id="check_cross_browser_testing" type="checkbox" @change="updatePrice" name="check_cross_browser_testing" class="switch is-rounded" v-model="check_cross_browser_testing" checked="checked">
                                            <label for="check_cross_browser_testing"><span class="has-tooltip-multiline" data-tooltip="Se non selezionato il sito sarà testato solo su Safari e Chrome. Se selezionato sarà testato anche su Firefox ed Opera">Crossbrowser testing</span></label>
                                        </div>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter">
                                        <div class="field">
                                            <div class="control">
                                                <label for="number_page"><span data-tooltip="In aggiunta alle 5 pagine base">Numero di pagine</span></label>
                                                <input class="input is-rounded" id="number_page" name="number_page" v-model="number_page" type="text" @change="updatePrice" placeholder="0">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column is-half is-offset-one-quarter">
                                        <div class="field">
                                            <div class="control">
                                                <label for="number_hour_support" class="has-tooltip-multiline" data-tooltip="Se si vuole avere supporto dopo il rilascio del sito">Numero ore di supporto</label>
                                                <input class="input is-rounded" id="number_hour_support" name="number_hour_support" v-model="number_hour_support" type="text" @change="updatePrice" placeholder="0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6-mobile">
                                    <div class="field">
                                        <div class="control has-text-centered button-margin-20">
                                            <button class="button is-primary" @click="step = 1">Indietro</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6-mobile">
                                    <div class="field">
                                        <div class="control has-text-centered button-margin-20">
                                            <button class="button is-primary" @click="step = 3">Avanti</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="has-text-centered" v-if="step === 3">
                            <h2>Informazioni di contatto</h2>
                            <div class="field">
                                <p class="control">
                                    <label for="email">Email</label>
                                    <input name="email" id="email" v-model="email" class="input" type="email" placeholder="Email">
                                </p>
                            </div>

                            <div class="field">
                                <p class="control">
                                    <label for="name">Nome</label>
                                    <input name="name" id="name" v-model="name" class="input" type="text" placeholder="Nome">
                                </p>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <label for="note">Maggiori dettagli</label>
                                    <textarea id="note" name="note" v-model="note" class="textarea is-primary" placeholder="Inserisci maggiori dettagli"></textarea>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-6-mobile">
                                    <div class="field">
                                        <div class="control has-text-centered button-margin-20">
                                            <button class="button is-primary" @click="step = 2">Indietro</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6-mobile">
                                    <div class="field">
                                        <div class="control has-text-centered button-margin-20">
                                            <button class="button is-primary" @click="sendForm">Fine</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <section class="hero has-text-centered">
                        <div class="hero-body">
                            <div class="container">
                                <h5 class="title">
                                    Grazie per aver utilizzato il simulatore ;) <br>
                                    Verrai ricontattato per maggiori informazioni sul progetto.
                                </h5>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            csrf: {
                type: String
            },
        },
        data() {
            return {
                step: 1,
                sending: false,
                sentForm: false,
                website_new: false,
                website_clone: false,
                technology_php_showcase: false,
                website_responsiveness: false,
                technology_wordpress: false,
                technology_wordpress_theme: false,
                technology_e_commerce: false,
                technology_php_webapp: false,
                technology_laravel_e_commerce: false,
                check_domain: false,
                check_ssl: false,
                check_social: false,
                check_index: false,
                check_analytics: false,
                check_search_console: false,
                check_blog: false,
                check_blog_laravel: false,
                check_backup_db: false,
                check_seo: false,
                check_cookie: false,
                check_multi_language: false,
                check_guide: false,
                check_content_insert: false,
                check_source_code: false,
                check_optimize_speed: false,
                check_ie_8: false,
                check_ie_9: false,
                check_ie_10: false,
                check_ie_11: false,
                check_cross_browser_testing: false,

                total_price:0,
                summary_text:'',

                number_page: 0,
                number_hour_support: 0,

                note:'',
                name:'',
                email:'',

                isFixed: false
            }
        },
        watch: {
            website_new: function(value, oldValue){

            },
        },
        mounted() {
            window.addEventListener('scroll', (event) => {
                this.isFixed = window.scrollY >= 100;
            });
        },
        methods: {
            updatePrice(){
                this.total_price =
                    (this.website_new? 0:0) +
                    (this.website_clone? 0:0) +
                    (this.website_responsiveness? 100:0) +
                    (this.technology_php_showcase? 400:0) +
                    (this.technology_php_webapp? 500:0) +
                    (this.technology_wordpress? 400:0) +
                    (this.technology_wordpress_theme? 300:0) +
                    (this.technology_e_commerce? 700:0) +
                    (this.technology_laravel_e_commerce? 900:0) +
                    (this.check_domain? 20:0) +
                    (this.check_ssl? 20:0) +
                    (this.check_social? 20:0) +
                    (this.check_index? 20:0) +
                    (this.check_analytics? 20:0) +
                    (this.check_search_console? 20:0) +
                    (this.check_blog? 20:0) +
                    (this.check_blog_laravel? 150:0) +
                    (this.check_backup_db? 50:0) +
                    (this.check_seo? 350:0) +
                    (this.check_cookie? 20:0) +
                    (this.check_multi_language? 300:0) +
                    (this.check_guide? 100:0) +
                    (this.check_content_insert? 200:0) +
                    (this.check_source_code? 300:0) +
                    (this.check_optimize_speed? 200:0) +
                    (this.check_ie_8? 10000:0) +
                    (this.check_ie_9? 7000:0) +
                    (this.check_ie_10? 5000:0) +
                    (this.check_ie_11? 1000:0) +
                    (this.check_cross_browser_testing? 250:0) +
                    (this.number_page * 80) +
                    (this.number_hour_support * 20);

                this.summary_text =
                    (this.website_new? 'Sito nuovo\n':'') +
                    (this.website_clone? 'Clone\n':'') +
                    (this.website_responsiveness? 'Responsive\n':'') +
                    (this.technology_php_showcase? 'Vetrina (massimo 3 pagine)\n':'') +
                    (this.technology_php_webapp? 'Web app (massimo 3 pagine)\n':'') +
                    (this.technology_wordpress? 'Wordpress\n':'') +
                    (this.technology_wordpress_theme? 'Wordpress con tema fornito\n':'') +
                    (this.technology_e_commerce? 'Wordpress con WooCommerce\n':'') +
                    (this.technology_laravel_e_commerce? 'Laravel e-commerce\n':'') +
                    (this.check_domain? 'Creazione dominio\n':'') +
                    (this.check_ssl? 'Https\n':'') +
                    (this.check_social? 'Social sharing optimization\n':'') +
                    (this.check_index? 'Robots follow\n':'') +
                    (this.check_analytics? 'Google analytics\n':'') +
                    (this.check_search_console? 'Google search console\n':'') +
                    (this.check_blog? 'Blog\n':'') +
                    (this.check_blog_laravel? 'Laravel Blog\n':'') +
                    (this.check_backup_db? 'Backup DB\n':'') +
                    (this.check_seo? 'SEO onsite\n':'') +
                    (this.check_cookie? 'Cookie popup\n':'') +
                    (this.check_multi_language? 'Multi language\n':'') +
                    (this.check_guide? 'Guida per utilizzo\n':'') +
                    (this.check_content_insert? 'Inserimento dei contenuti\n':'') +
                    (this.check_source_code? 'Codice sorgente\n':'') +
                    (this.check_optimize_speed? 'Ottimizzazione della velocità\n':'') +
                    (this.check_ie_8? 'IE8\n':'') +
                    (this.check_ie_9? 'IE9\n':'') +
                    (this.check_ie_10? 'IE10\n':'') +
                    (this.check_ie_11? 'IE11\n':'') +
                    (this.check_cross_browser_testing? 'Test crossbrowser\n':'') +
                    'Numero pagine aggiuntive: ' + this.number_page + '\n' +
                    'Numero ore supporto aggiuntive: ' + this.number_hour_support;
            },
            sendForm(){
                this.sending = true;
                const options = {
                    headers: {
                        'X-CSRF-Token': this.csrf
                    }
                };
                axios.post('/pricing-simulator/sendMessageWebsite', {
                    price: this.total_price,
                    email: this.email,
                    message: this.summary_text + '\n\n' + this.note,
                    name: this.name,
                }, options).then((response) => {
                    if(response.status === 200){
                        this.sentForm = true;
                    }
                    this.sending = false;
                }).catch((error) => {
                    if (error.response) {
                        this.sending = false;
                        console.log(error.response.data.errors);
                    }
                });
            },
            secondStep(){
                if(this.website_new || this.website_clone){
                    this.step = 2;
                }
            },
        }
    }
</script>

<style lang="scss">
    .button-margin-20{
        margin: 20px;
    }
    .block_checkbox{
        margin:7px 0 0;
    }
    .block_website_price_simulator{
        position:relative;
    }
    #sticky, .hero-body, #sticky.blockFixed{
        transition: all ease .2s;
    }
    #sticky.blockFixed{
        position: fixed;
        top:0;
        width:100%;
        z-index: 1000;
        .hero-body{
            transition: all ease .2s;
            padding: 3px;
        }
    }
    .siteScrollLockFixed.siteScrollLockFixed{
        margin-top:120px;
    }
</style>
