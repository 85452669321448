<template>
    <div>
        <div class="hero is-cover is-relative is-fullheight-with-navbar is-primary backimage"
             :style="{backgroundImage:'url('+img+')'}">
            <div class="hero-body backblack">
                <div class="container has-text-centered">
                    <vue-typed-js :strings="name" :typeSpeed="70" :backDelay="150" :startDelay="1500" >
                        <h1 class="title is-1">Hi! I'm <strong class="typing">Andrea Bettini</strong></h1>
                    </vue-typed-js>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {VueTypedJs} from 'vue-typed-js'

    export default {
        name: "hero-with-navbar",
        components: {
            VueTypedJs
        },
        props: {
            name: Array,
            img: String
        },
        methods: {

        }
    };
</script>

<style scoped>
    .backimage{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .backblack{
        background-color: rgba(0, 0, 0, 0.6);
    }
</style>
