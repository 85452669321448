<template>
    <div>
        <div>
            <div class="" v-for="item in listing">
                <div v-if="item.name == 'Pizze'">
                    <div class="columns" v-for="pizza in item.type">
                        <div class="column">{{ pizza.name }}</div>
                        <div class="column">
                            Teglia {{pizza.price_full}}
                            <div class="add_import" @click="incrementBy(pizza.price_full)">+</div>
                            <div class="remove_import" @click="decrementBy(pizza.price_full)">-</div>
                        </div>
                        <div class="column">
                            Mezza teglia {{pizza.price_half}}
                            <div class="add_import" @click="incrementBy(pizza.price_half)">+</div>
                            <div class="remove_import" @click="decrementBy(pizza.price_half)">-</div>
                        </div>
                        <div class="column">
                            Porzione abbondante {{pizza.price_portion_full}}
                            <div class="add_import" @click="incrementBy(pizza.price_portion_full)">+</div>
                            <div class="remove_import" @click="decrementBy(pizza.price_portion_full)">-</div>
                        </div>
                        <div class="column">
                            Porzione normale {{pizza.price_portion_full}}
                            <div class="add_import" @click="incrementBy(pizza.price_portion_full)">+</div>
                            <div class="remove_import" @click="decrementBy(pizza.price_portion_full)">-</div>
                        </div>
                    </div>
                </div>
                <div class="columns" v-else>
                    <div class="column">{{ item.name }}</div>
                    <div class="column">
                        <div class="add_import" @click="incrementBy(item.price)">+</div>
                        <div class="remove_import" @click="decrementBy(item.price)">-</div>
                    </div>
                </div>
            </div>
            <div class="total_price">{{ total_price }}</div>

        </div>
    </div>
</template>
<script>
    export default {
        name: "PizzaPriceSimulator",
        props: {
            csrf: {
                type: String
            },
            listing: {
                type: Array
            },
        },
        data() {
            return {
                total_price: 0,
            }
        },
        methods: {
            sendForm(){
                //const options = {
                //    headers: {
                //        'X-CSRF-Token': this.csrf
                //    }
                //};
                //axios.post('/pricing-simulator/pizza', {
                //    price: this.total_price,
                //}, options).then((response) => {
                //    if(response.status === 200){
                //    }
                //}).catch((error) => {
                //    if (error.response) {
                //        console.log(error.response.data.errors);
                //    }
                //});
            },
            incrementBy(value){
                this.total_price += value;
            },
            decrementBy(value){
                if(this.total_price >= value){
                    this.total_price -= value;
                }
            }
        }
    }
</script>

<style scoped>
    .total_price {
        width: 150px;
        background-color: #00d1b2;
        color: #000;
        position: fixed;
        bottom: 0;
        padding: 10px;
    }
</style>
