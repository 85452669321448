<template>
    <div class="card">
        <div class="card-content">
            <div class="media">
                <div class="media-left">
                    <figure class="image is-48x48">
                        <img :src="img" alt="Avatar" title="Avatar">
                    </figure>
                </div>
                <div class="media-content">
                    <p class="title is-4">{{ name }}</p>
                    <p class="subtitle is-6">{{ typology }}</p>
                </div>
            </div>

            <div class="content">
                <div class="social">
                    <div class="social_single" v-for="(item, index) in social">
                        <a :href="item.link" rel="noopener" target="_blank" class="social_icon" :title="item.name">
                            <div class="social_icon_div"><i :class="item.icon"></i></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'card-contact-personal',
        props: {
            img: {
                type: String,
                default: '/img/avatar.png'
            },
            name: {
                type: String,
                default: 'Andrea Bettini'
            },
            typology: {
                type: String,
                default: 'Web Developer'
            },
            social: {
                type: Array,
                default: () => [
                    {
                        name: 'Facebook',
                        icon: 'fab fa-facebook',
                        link: 'http://facebook.com/dark.nios',
                    },
                    {
                        name: 'Twitter',
                        icon: 'fab fa-twitter',
                        link: 'https://twitter.com/DarkNios',
                    },
                    {
                        name: 'LinkedIn',
                        icon: 'fab fa-linkedin',
                        link: 'https://www.linkedin.com/in/andrea-bettini-04b199105',
                    },
                    {
                        name: 'Instagram',
                        icon: 'fab fa-instagram',
                        link: 'https://www.instagram.com/darknios/',
                    },
                    {
                        name: 'Email',
                        icon: 'fa fa-envelope',
                        link: 'mailto:dark.nios@gmail.com',
                    },
                    {
                        name: 'Whatsapp',
                        icon: 'fab fa-whatsapp',
                        link: 'https://api.whatsapp.com/send?phone=393345371281',
                    }
                ]
            }
        },
        methods: {

        }
    }
</script>

<style lang="scss">
    .card .media:not(:last-child){
        margin-bottom:5px;
    }
    .social_single{
        display: inline-block;
        margin: 10px 3px;
        padding: 0 7px;
        background-color: #eee;
        width: 30px;
        text-align: center;
        a{
            color: #333;
        }
        .social_icon{
            .social_icon_div{

            }
        }
    }
</style>
