<template>
    <div>
        <div class="form_container" v-if="this.is_sent === false">
            <div class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label is-small" for="first_field">{{ firstField }}</label>
                                <div class="control has-icons-left has-icons-right">
                                    <input class="input is-small" v-model="first_value" type="text" name="first" id="first_field" :placeholder="firstField">
                                    <span class="icon is-small is-left"><i class="fas fa-user"></i></span>
                                    <span v-if="this.check_first_value === 1" class="icon is-small is-right"><i class="fas fa-check"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label is-small" for="email">{{ emailField }}</label>
                                <div class="control has-icons-left has-icons-right">
                                    <input class="input is-small" type="email" name="email" id="email" v-model="email" :placeholder="emailField">
                                    <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
                                    <span v-if="this.check_email_value === 1" class="icon is-small is-right"><i class="fas fa-check"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label is-small" for="message">{{ messageField }}</label>
                                <div class="control">
                                    <textarea class="textarea is-small" id="message" name="message" v-model="message" placeholder="Insert your message"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column has-text-centered">
                            <div class="field">
                                <p class="control">
                                    <button :class="'button is-primary'+(this.sending? ' is-loading':'')" @click="sendMessage()">
                                        {{ submitButtonText }}
                                    </button>
                                </p>
                                <p v-if="this.error" class="help is-danger">
                                    {{ this.message_error }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column has-text-centered">
                            <div class="">
                                <i class="fa fa-5x fa-check-circle"></i>
                            </div>
                            <div class="">{{ feedbackMessage }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "speed-message",
        props: {
            firstField: {
                type: String,
                default: 'Name'
            },
            emailField: {
                type: String,
                default: 'Email'
            },
            messageField: {
                type: String,
                default: 'Message'
            },
            feedbackMessage: {
                type: String,
                default: 'Message sent correctly!'
            },
            submitButtonText:{
                type: String,
                default: 'Submit'
            }
        },
        data: function() {
            return {
                first_value: '',
                email: '',
                message: '',
                check_first_value: 0,
                check_email_value: 0,
                is_sent: false,
                error: false,
                sending: false,
                message_error: 'This field is required'
            }
        },
        watch: {
            first_value: function(value, oldValue){
                if(value != ''){
                    this.check_first_value = 1;
                } else {
                    this.check_first_value = -1;
                }
            },
            email: function(value, oldValue){
                if(value != '' && this.validateEmail(value)){
                    this.check_email_value = 1;
                } else {
                    this.check_email_value = -1;
                }
            }
        },
        methods: {
            validateEmail(email){
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            sendMessage(){
                if(this.first_value != '' && this.message != '' && this.email != '' && this.validateEmail(this.email)){
                    this.error = false;
                    this.sending = true;
                    axios
                        .post('/sendMessage', {
                            name: '' + this.first_value,
                            email: '' + this.email,
                            message: '' + this.message,
                        })
                        .then(result => {
                            this.is_sent = result.success;
                            this.sending = !result.success;
                        })
                        .catch((error) => {
                            this.sending = false;
                            this.message_error = 'Something go wrong!';
                        });
                } else {
                    this.error = true;
                }
            }
        }
    }
</script>

<style lang="scss">

</style>
