<template>
    <div>
        <div class="title_section">
            <h2>{{ name }}</h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: "title-component",
        props: {
            name: String,
        }
    };
</script>

<style lang="scss">
    .title_section{
        margin:30px;
        color: hsl(171, 100%, 41%);
        h2{
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.5vw;
        }
    }
    @media only screen and (max-width: 768px){
        .title_section{
            margin:0 30px;
            h2{
                font-size: 22pt;
            }
        }
    }
</style>
