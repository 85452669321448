<template>
    <div>
        <carousel
            ref="carousel_tecnology"
            :responsive="{0:{items:1,nav:false},768:{items:3,nav:false},1024:{items:4,nav:false},1280:{items:5,nav:false}}"
        >
            <div class="tech_card" v-for="item in items">
                <div class="tech_img">
                    <img :src="'/img/loghi/'+item+'.png'" :alt="item" :title="item">
                </div>
                <div class="tech_title">
                    {{ item }}
                </div>
            </div>
        </carousel>
    </div>
</template>

<script>
    export default {
        props: {
            items: Array
        },
        methods: {
            info(value) {
                this.test = value
            }
        }
    }
</script>

<style lang="scss">
    .tech_card{
        .tech_img{
            width:150px;
            height: 150px;
            border-radius: 150px;
            border: 1px solid #eee;
            padding: 20px;
            margin: 25px auto;
            box-shadow: 0 0 10px #eee;
            transition: all ease .2s;
            -webkit-transition: all ease .2s;
            -moz-transition: all ease .2s;
            &:hover{
                transition: all ease .2s;
                -webkit-transition: all ease .2s;
                -moz-transition: all ease .2s;
                box-shadow: 0 0 15px #eee;
                transform: scale(0.8);
            }
            img{
                width:100%;
                overflow: hidden;
            }
        }
        .tech_title{
            text-align: center;
            font-style: italic;
            font-size: 14pt;
            margin:5px auto 30px;
        }
    }
    .has-shadow{
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
    }
</style>
