<template>
    <div>
        <div class="blur_bar"></div>
        <div class="padding_blur"></div>
    </div>
</template>
<script>
    export default {
        data: () => ({
            checking: false,
        }),
        methods: {

        }
    }
</script>

<style scoped>
    .blur_bar{
        position: absolute;
        width: 100%;
        height: 80px;
        background: rgba(255, 255, 255, 0);
        background: linear-gradient(180deg, rgba(227, 227, 227, 1), rgba(255, 255, 255, 0));
    }
    .padding_blur{
        position: relative;
        height: 80px;
        width:100%;
    }
</style>
